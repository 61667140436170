import React from 'react'
import { observer } from 'mobx-react'
import { Menu } from 'antd';
import { Header } from 'antd/lib/layout/layout';
import { Link, useHistory } from 'react-router-dom'

import {ReactComponent as Login} from '../../common/svg/login.svg';
import {ReactComponent as Logo} from '../../common/svg/logo.svg';

import './HeaderMenu.scss'

import { useUserStore } from '../../store'

const HeaderMenu: React.FC = () => {
  const { user, logout } = useUserStore()
  const history = useHistory()
  const { login } = user

  const doLogout = () => {
    logout()
    history.push('/login')
    console.log('logout')
  }

  return (
    <Header className='header-menu'>
      <Logo />
      <Menu className='menu' theme="dark" mode="horizontal" defaultSelectedKeys={['2']}>
        <Menu.Item className='item' key="1">
          <a href="https://discord.gg/tmvxqGhQWq" target="_blank" rel="noopener noreferrer">
            COMMUNITY
          </a>
        </Menu.Item>
        <Menu.Item className='item' key="2">
          {!login && <Link to='./register'>REGISTER</Link>}
          {login && <Link to='./profile'>USER PANEL</Link>}
        </Menu.Item>
        {!login && <Menu.Item className='item' key="3">
          <Link to='./login'>
            <Login className='login' />
          </Link>
        </Menu.Item>}
        {login &&
          <Menu.Item className='item' key="3">
            <span onClick={doLogout}>LOG OUT</span>
          </Menu.Item>
        }
      </Menu>
    </Header>
  )
}

export default observer(HeaderMenu)