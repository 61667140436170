import React from 'react'
import { observer } from 'mobx-react'
import { Button, Form, Input, InputNumber, notification } from 'antd'
import { SmileOutlined } from '@ant-design/icons';

import './DonationShop.scss'
import { Character } from '../../../common/interface'
import { useUserStore } from '../../../store'

interface DonationShopProps {
  character: Character
  gpc: number
}

const DonationShop: React.FC<DonationShopProps> = ({ character, gpc }) => {
  const { changeBp } = useUserStore()
  const [form] = Form.useForm();

  const onFinish = async (values: any) => {
    const result = await changeBp(values)
    
    if (result.code === 1) {
      notification.open({
        message: 'Donation',
        description: result.message,
        icon: <SmileOutlined style={{ color: '#FBCA4C' }} />,
        duration: 5,
      });
    }
  };

  return (
    <div className='donation-shop'>
      {gpc === 0 &&
      <h3>
        Current GPC(s) is 0
      </h3>
      }
      {gpc > 0 &&
      <>
        <div>
          <h3>Under construction</h3>
          <p>Currently we are offering only buy Bloody Paagrio(s) in game via GPC</p>
          <p>Soon we will offer some services</p>
        </div>
        <Form
            className="bp-form"
            form={form}
            name="basic"
            onFinish={onFinish}
            autoComplete="off"
          >
            <Form.Item
              label="Character"
              name="char_name"
              initialValue={character.char_name}
            >
              <Input disabled={true} />
            </Form.Item>
            <Form.Item
              label="BP Amount"
              name="count"
              initialValue={0}
              rules={[{ required: true }]}
            >
              <InputNumber min={1} max={gpc} />
            </Form.Item>
            <Form.Item className='submit'>
              <Button className='button' type="primary" htmlType="submit">
                Change
              </Button>
            </Form.Item>
          </Form>
        </>}
    </div>
  )
}

export default observer(DonationShop)