import React, { useState } from 'react'
import { observer } from 'mobx-react'
import { Avatar, Button, notification, Select } from 'antd';
import { UserOutlined, SmileOutlined } from '@ant-design/icons';

import './Profile.scss'

import { useUserStore } from '../../store'
import { Character } from '../../common/interface';

import {ReactComponent as Arrow} from '../../common/svg/arrow.svg';
import CharacterPanel from './CharacterPanel/CharacterPanel';
import ChangePasswordForm from './ChangePasswordForm/ChangePasswordForm';
import ChangeEmail from './ChangeEmailForm/ChangeEmailForm';
import ReferalPanel from './ReferalPanel/ReferalPanel';
import DonationSender from './DonationSender/DonationSender';
import DonationPanel from './DonationPanel/DonationPanel';
import DonationShop from './DonationShop/DonationShop';


const Profile: React.FC = () => {
  const { Option } = Select;
  const { user, referal, refered } = useUserStore()
  const { characters } = user
  const [section, setSection] = useState('chars')
  const [settings, setSettings] = useState(false)
  const [dropdown, setDropdown] = useState('Select character')
  const [character, setCharacter] = useState({} as Character)

  const selectSection = element => {
    const section = element.target.getAttribute("data-section")
    if (section === 'refs') {
      setDropdown('Select character')
      setCharacter({} as Character)
    }
    setSection(section)
  }

  const toggleSettings = () => {
    setSettings(!settings)
  }

  const onChange = (value: string) => {
    const character = characters.find(char => char.char_name === value)
    if (character) {
      setDropdown(value)
      setSection('chars')
      setCharacter(character)
    }
  }

  const copyStringToClipboard = () => {
    var el = document.createElement('textarea');
    el.value = `${window.location.origin}/register/${user.login}`;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);

    return notification.open({
      message: 'Referal',
      description: 'Link copy to clipboard',
      icon: <SmileOutlined style={{ color: '#FBCA4C' }} />,
      duration: 5,
    });
 }

  return (
    <div className='profile'>
      <div className='user-content'>
        <div className='options'>
          <div>
            <Avatar shape="square" size={120} icon={<UserOutlined />} />
          </div>
          <div className='buttons-group'>
            <Button htmlType="button" className="button" onClick={selectSection} data-section='refs'>
              Referals
            </Button>
            <Button disabled={true} htmlType="button" className="button">
              Vote for us
            </Button>
            <Button htmlType="button" className="button" onClick={toggleSettings}>
              Settings
            </Button>
            <Button disabled={true} htmlType="button" className="button">
              Ranking
            </Button>
          </div>
        </div>
        <div className='donate'>
          <div className='info'>
            <p className='coins'>GPC:
              <span className='amount'>
                {user.donate}
              </span>
            </p>
            <Button htmlType="button" className="button" onClick={selectSection} data-section='donate'>
              Donate
            </Button>
          </div>
          {characters && <Select
            value={dropdown}
            className='char-select'
            suffixIcon={<Arrow />}
            placeholder="Select character"
            optionFilterProp="children"
            onChange={onChange}
          >
          {characters.map(char =>
            <Option className='character' key={char.char_name} value={char.char_name}>{char.char_name} - {char.level}</Option>
          )}
          </Select>}
        </div>
      </div>
      <div className='section'>
        {section === 'chars' && <CharacterPanel character={character} referal={referal} />}
        {section === 'refs' && <ReferalPanel referal={referal} refered={refered} />}
        {section === 'donate' && <DonationPanel />}
        {section === 'shop' && <DonationShop character={character} gpc={user.donate} />}
      </div>
      <div className={`change ${settings && 'show'}`}>
        <ChangePasswordForm />
        <ChangeEmail />
      </div>
      <div className='donate-shop'>
        {user.access_level === 9 && <DonationSender />}
        <Button htmlType="button" className="button" onClick={selectSection} data-section='shop' disabled={!character.char_name}>
          Donate Shop
        </Button>
        <Button htmlType="button" className="button" onClick={copyStringToClipboard}>
          Referal Link
        </Button>
      </div>
    </div>
  )
}

export default observer(Profile)