import React, { useState } from 'react'
import { observer } from 'mobx-react'
import { Button, Form, Input, Modal, notification } from 'antd'
import { SmileOutlined } from '@ant-design/icons';

import './ChangeEmailForm.scss'

import { useUserStore } from '../../../store'


const ChangeEmailForm: React.FC = () => {
  const { changeEmail } = useUserStore()
  const [form] = Form.useForm();
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    form.resetFields()
  };

  const onFinish = async (values: any) => {
    const result = await changeEmail(values)
    if (result.code === 1) {
      form.resetFields()
      handleCancel()
      notification.open({
        message: 'Email',
        description: result.message,
        icon: <SmileOutlined style={{ color: '#FBCA4C' }} />,
        duration: 5,
      });
    } else {
      notification.open({
        message: 'Email',
        description: result.message,
        icon: <SmileOutlined style={{ color: 'rgb(197, 14, 50)' }} />,
        duration: 5,
      });
    }
  };

  return (
    <div className='change-email'>
      <Button className='button' type="primary" onClick={showModal}>
        Change Email
      </Button>
      <Modal className='change-modal' visible={isModalVisible} onCancel={handleCancel} footer={null}>
          <Form
          className="email-form"
          form={form}
          name="basic"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          autoComplete="off"
        >
          <Form.Item
            label="New email"
            name="email"
            rules={[{ required: true, type: 'email', message: 'Please write your new email' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item className='submit'>
            <Button className='button' type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  )
}

export default observer(ChangeEmailForm)