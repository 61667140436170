import { observable, action, makeObservable} from 'mobx'

import RootStore from '../RootStore'

export default class AppStore {
  @observable rootStore: RootStore
  @observable modal: boolean = false

  constructor(rootStore: RootStore) {
    makeObservable(this)
    this.rootStore = rootStore
  }

  @action
  showModal = () => {
    this.modal = true
  }

  @action
  hideModal = () => {
    this.modal = false
  }
}