import { Cookies } from 'react-cookie'
import { print } from 'graphql'

import { api } from '../../common/utils'

import { Account, AccountInfo, ChangeBp, ChangeEmail, ChangePassword, Donation, Login, Message, RegisterAccount } from '../../common/interface';
import {
  ChangeEmailMutation,
  ChangePasswordMutation,
  ClaimReferalRewardMutation,
  FindAccountQuery,
  LoginMutation,
  RegisterMutation,
  SendDonationMutation,
  ExchangeBpsMutation,
  UserInfoQuery 
} from './graphql';

const login = async (account: Account):Promise<any> => {
  const query = print(LoginMutation)
  const { data: { data } } = await api.post('', {
    query,
    variables: account
  })
  const { login: { token, user, referal, refered, message } } = data

  if (message.code === 4 || message.code === 3) {
    return {
      message
    }
  }

  if (message.code === 1) {
    createSession(token)
  }

  return {
    user,
    referal,
    refered,
    message
  }
}

const logout = ():void => {
  const cookies = new Cookies()
  if (cookies.get('token')) {
    cookies.set('token', '')
  }
  api.defaults.headers.common['Authorization'] = ''
}

const userInfo = async ():Promise<AccountInfo> => {
  const query = print(UserInfoQuery)
    const { data: { data, errors } } = await api.post('', {
      query,
    })
    
    if (errors) {
      const error = errors[0].message
      return error
    }

    const { user } = data
    return user
}

const changeEmail = async (email: ChangeEmail):Promise<Message> => {
  const query = print(ChangeEmailMutation)
  const { data: { data } } = await api.post('', {
    query,
    variables: email
  })

  const { changeEmail } = data
  return changeEmail
}

const changePassword = async (passwords: ChangePassword):Promise<Message> => {
  const query = print(ChangePasswordMutation)
  const { data: { data } } = await api.post('', {
    query,
    variables: passwords
  })
  
  const { changePassword } = data
  return changePassword
}

const register = async (account: RegisterAccount, referal_account: any):Promise<Login> => {
  const query = print(RegisterMutation)
  const { data: { data }} = await api.post('', {
    query,
    variables: { ...account, referal_account }
  })

  const { register: { token, user, message, referal } } = data
  if (message.code === 4 || message.code === 3) {
    return message
  }

  if (message.code === 1) {
    createSession(token)
  }
  
  return {
    user,
    referal,
    message
  }
}

const claimReferalReward = async (char_name: string, type: number, referal_name: string) => {
  const query = print(ClaimReferalRewardMutation)
  const { data: { data }} = await api.post('', {
    query,
    variables: {
      char_name,
      type,
      referal_name
    }
  })
  const { claimReferalReward } = data

  return claimReferalReward
}

const findAccount = async (account_name: string):Promise<Account[]> => {
  const query = print(FindAccountQuery)
  const { data: { data } } = await api.post('', {
    query,
    variables: {
      account_name
    }
  })
  
  const { findAccount } = data
  return findAccount
}

const deliverDonation = async (donation: Donation) => {
  const query = print(SendDonationMutation)
  const { data: { data } } = await api.post('', {
    query,
    variables: donation
  })

  const { deliverDonation } = data
  return deliverDonation
}

const changeBp = async (change: ChangeBp) => {
  const query = print(ExchangeBpsMutation)
  const { data: { data } } = await api.post('', {
    query,
    variables: change
  })

  const { changeBp } = data
  return changeBp
}

export {
  changeEmail,
  changePassword,
  login,
  logout,
  userInfo,
  register,
  claimReferalReward,
  findAccount,
  deliverDonation,
  changeBp
}

const createSession = (token: string) => {
  const cookies = new Cookies();
  const current = new Date();
  const nextYear = new Date();
  nextYear.setFullYear(current.getFullYear() + 1)
  cookies.set('token', token, { path: '/', expires: nextYear});
  api.defaults.headers.common['Authorization'] = `Bearer ${cookies.get('token')}`;
}