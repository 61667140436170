import React from 'react'
import { observer } from 'mobx-react'

import './DonationPanel.scss'
import { Button } from 'antd'

const DonationPanel: React.FC = () => {

  return (
    <div className='donation-panel'>
      <div>
        <h3>Under construction</h3>
        <p>Currently we are working on that section, you can check how to donate in the main site</p>
      </div>
      <Button htmlType="button" className="button" data-section='donate'>
        <a href="http://granpaagrio.com/Donations.html" target="_blank" rel="noreferrer">
          Donation Section
        </a>
      </Button>
    </div>
  )
}

export default observer(DonationPanel)