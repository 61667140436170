import { observable, action, makeObservable} from 'mobx'

import RootStore from '../RootStore'
import * as UserService from './UserStore.service'

import { Account, ChangeBp, ChangeEmail, ChangePassword, Donation, Message, Referal, RegisterAccount, User } from '../../common/interface'

export default class UserStore {
  @observable rootStore: RootStore
  @observable user: User = {} as User
  @observable referal: Referal = {} as Referal
  @observable refered: Referal[] = []

  constructor(rootStore: RootStore) {
    makeObservable(this)
    this.rootStore = rootStore
  }

  @action
  login = async (account:Account):Promise<Message> => {
    const { user, referal, refered, message } = await UserService.login(account)
    
    user && this.setUser(user)
    referal && this.setReferal(referal)
    refered && this.setRefered(refered)
    return message
  }

  @action
  logout = ()  => {
    UserService.logout()
    this.user = {} as User
    this.referal = {} as Referal
  }

  @action
  register = async (account: RegisterAccount, referal_account: string = ''):Promise<Message> => {
    const { user, referal, message } = await UserService.register(account, referal_account)
    
    this.setUser(user)
    referal && this.setReferal(referal)
    
    return message
  }

  @action
  userInfo = async (): Promise<void> => {
    const { user, referal, refered } = await UserService.userInfo()
    
    user && this.setUser(user)
    referal && this.setReferal(referal)
    refered && this.setRefered(refered)
  }

  @action
  changePassword = async (passwords: ChangePassword) => {
    const changePassword = await UserService.changePassword(passwords)

    return changePassword
  }

  @action
  changeEmail = async (email: ChangeEmail) => {
    const changeEmail = await UserService.changeEmail(email)

    return changeEmail
  }

  @action
  claimReferalReward = async (char_name: string, type: number, referal_name: string = '') => {
    this.rootStore.appStore.showModal()
    const reward = await UserService.claimReferalReward(char_name, type, referal_name)

    this.rootStore.appStore.hideModal()
    return reward
  }

  @action
  findAccount = async (account_name: string): Promise<Account[]> => {
    const findAccount = await UserService.findAccount(account_name)

    return findAccount
  }

  @action
  deliverDonation = async (donation: Donation) => {
    this.rootStore.appStore.showModal()
    const deliverDonation = await UserService.deliverDonation(donation)
    
    this.rootStore.appStore.hideModal()
    return deliverDonation
  }

  @action
  changeBp = async (change: ChangeBp) => {
    this.rootStore.appStore.showModal()
    const changeBp = await UserService.changeBp(change)
    
    this.rootStore.appStore.hideModal()
    return changeBp
  }

  @action
  setUser = (user: User): void => {
    this.user = user
  }

  @action
  setReferal = (referal: Referal): void => {
    this.referal = referal
  }

  @action
  setRefered = (refered: Referal[]): void => {
    this.refered = refered
  }
}