import React from 'react'
import { observer } from 'mobx-react'
import { Button, Form, Input, notification } from 'antd'
import { Link, useHistory, useParams } from 'react-router-dom'
import { SmileOutlined } from '@ant-design/icons';

import { useAppStore, useUserStore } from '../../store'

import './Register.scss'

import {ReactComponent as Logo} from '../../common/svg/logoColor.svg';

const Register: React.FC = () => {
  const { register } = useUserStore()
  const { showModal, hideModal } = useAppStore()
  const history = useHistory()
  const { referal_account }: any = useParams()
  const [form] = Form.useForm();

  const onFinish = async (values: any) => {
    const result = await register(values, referal_account)
    showModal()
    if (result.code === 1) {
      notification.open({
        message: 'Register',
        description: result.message,
        icon: <SmileOutlined style={{ color: '#FBCA4C' }} />,
        duration: 5,
      });
      hideModal()
      setTimeout(() => {
        history.push('/profile')
      }, 500)
    } else {
      notification.open({
        message: 'Register',
        description: result.message,
        icon: <SmileOutlined style={{ color: 'rgb(197, 14, 50)' }} />,
        duration: 5,
      });
      hideModal()
    }
  };

  return (
    <div className='register'>
      <Logo className='logo' />
      <h3 className='header'>Register</h3>
      <Form
        form={form}
        name="register"
        onFinish={onFinish}
        scrollToFirstError
        autoComplete="off"
      >
        <Form.Item
            className='input'
          name="login"
          label="Login"
          rules={[{ required: true, message: 'Please input your username!', whitespace: true }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          className='input'
          name="email"
          label="E-mail"
          tooltip="Need to be a valid email."
          rules={[
            {
              type: 'email',
              message: 'The input is not valid E-mail!',
            },
            {
              required: true,
              message: 'Please input your E-mail!',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          className='input'
          name="password"
          label="Password"
          rules={[
            {
              required: true,
              message: 'Please input your password!',
            },
          ]}
          hasFeedback
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          className='input'
          name="password_confirmation"
          label="Confirm Password"
          dependencies={['password']}
          hasFeedback
          rules={[
            {
              required: true,
              message: 'Please confirm your password!',
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error('Passwords do not match!'));
              },
            }),
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item className='register-container'>
          <Button type="primary" htmlType="submit" className="button">
            Register
          </Button>
        </Form.Item>
        <Form.Item className='login-container'>
          <span>I already have an account.</span> <Link to="/login"> Login </Link>
        </Form.Item>
      </Form>
    </div>
  )
}

export default observer(Register)