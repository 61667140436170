import React, { useState } from 'react'
import { observer } from 'mobx-react'
import { Button, Form, InputNumber, Modal, notification } from 'antd'
import { AutoComplete } from 'antd';
import { SmileOutlined } from '@ant-design/icons';

import './DonationSender.scss'

import { useUserStore } from '../../../store';
import { Account } from '../../../common/interface'


const DonationSender: React.FC = () => {
  const [form] = Form.useForm()
  const { findAccount, deliverDonation } = useUserStore()
  const [accounts, setAccounts] = useState([] as any)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [isDisabled, setIsDisabled] = useState(true)
  const [account, setAccount] = useState('')

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    form.resetFields()
  };

  const onFinish = async (values: any) => {
    const result = await deliverDonation(values)
    if (result.code === 1) {
      notification.open({
        message: 'Donation',
        description: result.message,
        icon: <SmileOutlined style={{ color: '#FBCA4C' }} />,
        duration: 5,
      });
    }
  };

  const onSearch = async (value) => {
    let search = [] as Account[]
    if (value.length > 2) {
      search = await findAccount(value)
      if (search && search.length) {
        const options = search.map(item => { return { value: item.login } })
        setAccounts(options)
      }
    } else {
      setAccounts([])
    }
    const accountExist = search.find(option => option.login === value)
    accountExist ? setIsDisabled(false) : setIsDisabled(true)
  };

  const selectAccount = value => {
    setAccount(value)
  }

  return (
    <div className='donation-sender'>
      <Button className='button' type="primary" onClick={showModal}>
        Send Donation
      </Button>
      <Modal className='donation-modal' visible={isModalVisible} onCancel={handleCancel} footer={null}>
        <Form
          className="donation-form"
          form={form}
          name="basic"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          autoComplete="off"
        >
          <Form.Item
            label="Account"
            name="account_name"
            className='item'
          >
            <AutoComplete
              className='autocomplete'
              options={accounts}
              value={account}
              placeholder="search an account"
              onChange={onSearch}
              onSelect={selectAccount}
              filterOption={(inputValue, option) =>
                option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
              }
            />
          </Form.Item>
          <Form.Item
            label="Donation count"
            name="count"
            initialValue={0}
          >
            <InputNumber min={0} disabled={isDisabled} />
          </Form.Item>
          <Form.Item className='submit'>
            <Button className='button' type="primary" htmlType="submit">
              Send
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  )
}

export default observer(DonationSender)