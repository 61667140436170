import React from 'react'
import { observer } from 'mobx-react'
import { Button, Form, Input, notification } from 'antd'
import { UserOutlined, LockOutlined, SmileOutlined } from '@ant-design/icons';
import { Link, useHistory } from 'react-router-dom'

import { useAppStore, useUserStore } from '../../store'

import './Login.scss'
import {ReactComponent as Logo} from '../../common/svg/logoColor.svg';
import {ReactComponent as Download} from '../../common/svg/download.svg';

const Login: React.FC = () => {
  const { login } = useUserStore()
  const { showModal, hideModal } = useAppStore()
  const history = useHistory()

  const onFinish = async (values: any) => {
    showModal()
    const result = await login(values)
    if (result.code === 1) {
      notification.open({
        message: 'Log in',
        description: result.message,
        icon: <SmileOutlined style={{ color: '#FBCA4C' }} />,
        duration: 5,
      });
      hideModal()
      history.push('/profile')
    } else {
      notification.open({
        message: 'Log in',
        description: result.message,
        icon: <SmileOutlined style={{ color: 'rgb(197, 14, 50)' }} />,
        duration: 5,
      });
      hideModal()
    }
  };

  return (
    <div className="login-form">
      <Logo className='logo' />
      <h3 className='header'>Log in</h3>
      <Form
        className='form'
        name="normal_login"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        autoComplete="off"
      >
        <Form.Item
          className='input'
          name="login"
          rules={[{ required: true, message: 'Please input your Username!' }]}
        >
          <Input
            prefix={<UserOutlined className="site-form-item-icon" />} 
            placeholder="Username"
          />
        </Form.Item>
        <Form.Item
            className='input'
          name="password"
          rules={[{ required: true, message: 'Please input your Password!' }]}
        >
          <Input
            prefix={<LockOutlined className="site-form-item-icon" />}
            type="password"
            placeholder="Password"
          />
        </Form.Item>

        <Form.Item className='login-container'>
          <Button type="primary" htmlType="submit" className="button">
            Log in
          </Button>
        </Form.Item>
        <Form.Item className='register-container'>
          <span>I don't have account.</span> <Link to="/register"> Register </Link>
        </Form.Item>
      </Form>
      <Button type="primary" htmlType="button" className="download-button">
        <Download className='download' /> Download
      </Button>
    </div>
  )
}

export default observer(Login)