import React from 'react'

import RootStore from './RootStore'

export const store = new RootStore()

export const storeContext = React.createContext<RootStore | null>(null)

const RootStoreProvider: React.FC = ({ children }): JSX.Element => {
    return <storeContext.Provider value={store}>{children}</storeContext.Provider>
}

export default RootStoreProvider