import React, { useState } from 'react'
import { observer } from 'mobx-react'
import { notification, Select, Tooltip } from 'antd';
import { SmileOutlined } from '@ant-design/icons';

import './ReferalPanel.scss'

import {ReactComponent as Reward} from '../../../common/svg/reward.svg';

import { useUserStore } from '../../../store';
import { Referal } from '../../../common/interface';


interface ReferalPanelProps {
  referal: Referal
  refered: Referal[]
}


const ReferalPanel: React.FC<ReferalPanelProps> = ({ referal, refered }) => {
  const { user, claimReferalReward } = useUserStore()
  const { Option } = Select;
  const [character, setCharacter] = useState('')
  const referalText = referal && referal.account ? `(${referal.account.login})` : 'No referal'

  const status = (status: number) => {
    switch (status) {
      case 1:
        return 'available'
      case 2:
        return 'claimed'
      default:
        return 'not-available'
    }
  }
  
  const claim = async (e: any) => {
    const isAvailable= e.target.getAttribute("data-status") === 'available'
    const referal = e.target.getAttribute("data-referal")
    const type = parseInt(e.target.getAttribute("data-type"))
    if (character && isAvailable) {
      const result = await claimReferalReward(character, type, referal)
      if (result.code === 1) {
        notification.open({
          message: 'Referal',
          description: result.message,
          icon: <SmileOutlined style={{ color: '#FBCA4C' }} />,
          duration: 5,
        });
      } else {
        notification.open({
          message: 'Referal',
          description: result.message,
          icon: <SmileOutlined style={{ color: 'rgb(197, 14, 50)' }} />,
          duration: 5,
        });
      }
    }
    if(!character)
      notification.open({
        message: 'Referal',
        description: 'Select a character',
        icon: <SmileOutlined style={{ color: 'rgb(197, 14, 50)' }} />,
        duration: 5,
      });
    return
  }

  function onChange(value) {
    setCharacter(value)
  }

  return (
    <div className='referal-panel'>
      <div className='referal'>
        <h3 className='title'>Referal</h3>
        <h3 className='char-info'>{ referalText }</h3>
      </div>
      {user.characters && <Select
        className='char-select'
        placeholder="Select a character"
        optionFilterProp="children"
        onChange={onChange}
      >
      {user.characters.map(char =>
        <Option className='character' key={char.char_name} value={char.char_name}>{char.char_name}</Option>
      )}
      </Select>}
      <h3 className='title'>Refereds</h3>
      <>
        {refered && refered.map(refered => 
          <div className='refered' key={refered.account.login}>
            <h3 className='char-info'>{ refered.account.login }</h3>
            <div className='reward-list'>
              <Tooltip title="Lv. 108">
                <Reward className='reward' data-status={status(refered.first_gift)} data-type={4} data-referal={refered.account.login} onClick={claim} />
              </Tooltip>
              <Tooltip title="Lv. 109">
                <Reward className='reward' data-status={status(refered.second_gift)} data-type={5} data-referal={refered.account.login} onClick={claim}/>
              </Tooltip>
              <Tooltip title="Lv. 110">
                <Reward className='reward' data-status={status(refered.third_gift)} data-type={6} onClick={claim}/>
              </Tooltip>
            </div>
          </div>
        )}
      </>
    </div>
  )
}

export default observer(ReferalPanel)