import { observer } from 'mobx-react';
import { Redirect, Route, Switch } from 'react-router';
import React, { useEffect} from 'react';
import { Cookies } from 'react-cookie'

import './App.scss';

import Spinner from './component/Spinner/Spinner';
import { InviteRoute, ProtectedRoute } from './component';
import { HeaderMenu, Login, Profile, Register } from './template';
import { useAppStore, useUserStore } from './store';

const App: React.FC = () => {
  const cookies = new Cookies()
  const { userInfo } = useUserStore()
  const { modal } = useAppStore()
  const userIsLogged = cookies.get('token')

  useEffect(() => {
    if (userIsLogged) {
      userInfo()
    }
  }, [userInfo, userIsLogged])

  return (
    <div className="app">
      <div className='video-container'>
        <video autoPlay loop muted>
            <source src="./videos/background.mp4" type="video/mp4" />
        </video>
      </div>
      <HeaderMenu />
      <div className='container'>
        <Switch>
          <InviteRoute exact to='profile' path='/login' component={Login} />
          <InviteRoute exact to='profile' path='/register/:referal_account?' component={Register} />
          <ProtectedRoute exact  to='login' path='/profile/:username?' component={Profile} />
          <Route exact path='*' render={() => <Redirect to='/profile' />} />
        </Switch>
      </div>
      <Spinner show={modal}/>
    </div>
  );
}

export default observer(App);
