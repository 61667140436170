import React from 'react'
import { observer } from 'mobx-react'
import { Card, notification, Tooltip } from 'antd'
import { SmileOutlined } from '@ant-design/icons';
import moment from 'moment'

import './CharacterPanel.scss'

import {ReactComponent as Reward} from '../../../common/svg/reward.svg';
import { Character, Referal } from '../../../common/interface'
import { useUserStore } from '../../../store'

interface CharacterPanelProps {
  character: Character
  referal: Referal
}

const CharacterPanel: React.FC<CharacterPanelProps> = ({ character, referal }) => {
  const { claimReferalReward } = useUserStore()
  const hours = moment.duration(character.onlinetime, 'seconds').asHours()

  const claim = async (e: any) => {
    const isAvailable= e.target.getAttribute("data-status") === 'available'
    const char = e.target.getAttribute("data-char")
    const type = parseInt(e.target.getAttribute("data-type"))
    if (character && isAvailable) {
      const result = await claimReferalReward(char, type)
      return notification.open({
        message: 'Character',
        description: result.message,
        icon: <SmileOutlined style={{ color: '#FBCA4C' }} />,
        duration: 5,
      });
    }
    return
  }

  const status = (status: number, level: number, reqLevel: number) => {
    if (status === 0) {
      if (level >= reqLevel) {
        return 'available'
      } else {
        return 'not-available'
      }
    } else {
      return 'claimed'
    }
  }

  console.log(character.onlinetime)

  return (
    <div className='character-panel'>
      {!character.char_name && <h3 className='no-character'>Select character to see details</h3>}
      {character.char_name &&
        <Card
          className='card'
          title={`${character.char_name} (${character.level})`}
        >
          <p className='text'>Clan: <span className='info'>{character.clan ? character.clan.name : 'No clan'}</span></p>
          <p className='text'>Alliance: <span className='info'>{character.clan?.ally ? character.clan.ally.ally_name : 'No ally'}</span></p>
          <p className='text'>Hours played: <span className='info'>{Math.trunc(hours)}h</span></p>
          <p className='text'>Status: <span className='info'>{character.online ? 'Online' : 'Offline'}</span></p>
          <p className='text'>PvP: <span className='info'>{character.pvpkills}</span> - Pk: <span className='info'>{character.pkkills}</span></p>
          <div className={`reward-list ${referal.account && 'visible'}`}>
            {referal.account && <>
              <Tooltip title="Lv. 108">
                <Reward className='reward' data-status={status(referal.first_gift, character.level, 108)} data-type={1} data-char={character.char_name} onClick={claim} />
              </Tooltip>
              <Tooltip title="Lv. 109">
                <Reward className='reward' data-status={status(referal.second_gift, character.level, 109)} data-type={2} data-char={character.char_name} onClick={claim} />
              </Tooltip>
              <Tooltip title="Lv. 110">
                <Reward className='reward' data-status={status(referal.third_gift, character.level, 110)} data-type={3} data-referal={character.char_name} onClick={claim} />
              </Tooltip>
            </>}
          </div>
        </Card>
      }
    </div>
  )
}

export default observer(CharacterPanel)