import { loader } from 'graphql.macro'

const ChangeEmailMutation = loader('./Mutation/ChangeEmail.graphql')
const ChangePasswordMutation = loader('./Mutation/ChangePassword.graphql')
const LoginMutation = loader('./Mutation/Login.graphql')
const UserInfoQuery = loader('./Query/UserInfo.graphql')
const RegisterMutation = loader('./Mutation/Register.graphql')
const ClaimReferalRewardMutation = loader('./Mutation/ClaimReferalReward.graphql')
const FindAccountQuery = loader('./Query/FindAccount.graphql')
const SendDonationMutation = loader('./Mutation/SendDonation.graphql')
const ExchangeBpsMutation = loader('./Mutation/ExchangeBps.graphql')

export {
  ChangeEmailMutation,
  ChangePasswordMutation,
  LoginMutation,
  UserInfoQuery,
  RegisterMutation,
  ClaimReferalRewardMutation,
  FindAccountQuery,
  SendDonationMutation,
  ExchangeBpsMutation
}