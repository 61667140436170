import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom'
import { CookiesProvider } from 'react-cookie'

import './index.scss';

import App from './App';
import RootStoreProvider from './store/RootStore.provider';

import reportWebVitals from './reportWebVitals';

ReactDOM.render(
  <BrowserRouter>
    <CookiesProvider>
      <RootStoreProvider>
        <App />
      </RootStoreProvider>
    </CookiesProvider>
  </BrowserRouter>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
