import { observable } from 'mobx'
import UserStore from './UserStore/UserStore'
import AppStore from './AppStore/AppStore'

export default class RootStore {
    @observable userStore: UserStore
    @observable appStore: AppStore

    constructor() {
        this.userStore = new UserStore(this)
        this.appStore = new AppStore(this)
    }
}