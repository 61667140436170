import React from 'react'
import { observer } from 'mobx-react'
import { Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'

import './Spinner.scss'

interface SpinnerProps {
  show: Boolean
}

const Spinner: React.FC<SpinnerProps> = ({ show }) => {
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  return (
    <div className={`spinner-modal ${!show && 'hide'}`}>
        <Spin indicator={antIcon} />
    </div>
  )
}

export default observer(Spinner)