import React from 'react';
import { storeContext } from './RootStore.provider';
import RootStore from './RootStore';

export const useRootStore = (): RootStore => {
    const rootStore = React.useContext(storeContext);

    if (!rootStore) { 
        throw Error('Missing RootStoreProvider');
    }

    return rootStore;
}